<script setup lang="ts">
import {useAuthStore} from "../store/authStore";

const teams = [{
  label: 'Nuxt',
  avatar: {
    src: 'https://avatars.githubusercontent.com/u/23360933?s=200&v=4'
  },
  click: () => {
    team.value = teams[0]
  }
}, {
  label: 'NuxtLabs',
  avatar: {
    src: 'https://avatars.githubusercontent.com/u/62017400?s=200&v=4'
  },
  click: () => {
    team.value = teams[1]
  }
}]

const actions = [{
  label: 'Create team',
  icon: 'i-heroicons-plus-circle'
}, {
  label: 'Manage teams',
  icon: 'i-heroicons-cog-8-tooth'
}]

const team = ref(teams[0])
const authStore = useAuthStore();

</script>

<template>
  <UDropdown
    v-slot="{ open }"
    mode="hover"
    :items="[actions]"
    class="w-full"
    :ui="{ width: 'w-full' }"
    :popper="{ strategy: 'absolute' }"
  >
    <!--class="w-full flex flex-col items-center"-->
    <UButton
      color="gray"
      variant="ghost"
      :class="[open && 'bg-gray-50 dark:bg-gray-800']"
      class="w-full"
    >
      <NuxtImg
        src="https://candidate.fra1.cdn.digitaloceanspaces.com/public/textonly.png"
        size="2xs"
      />

      <!--<span class="truncate text-gray-900 dark:text-white font-semibold">{{ authStore.companyName }}</span>-->
    </UButton>
  </UDropdown>
</template>
