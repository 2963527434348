<script setup lang="ts">
import ProfileAvatar from "../components/avatar/ProfileAvatar.vue";
import {ref} from 'vue';
import TeamsDropdown from "../components/TeamsDropdown.vue";


const route = useRoute()
const appConfig = useAppConfig()
//const { isHelpSlideoverOpen } = useDashboard()


const linkSettingsDocu = [
  {
    id: 'settings',
    label: 'Settings',
    to: '/settings',
    defaultOpen: false,
    icon: 'i-heroicons-cog-8-tooth',
    children: [{
      label: 'User',
      to: '/settings/user'
    },
      {
        label: 'Members',
        to: '/settings/members'
      }, {
        label: 'Templates',
        to: '/settings/templates'
      },
      {
        label: 'Company',
        to: '/settings/company'
      }],
    tooltip: {
      text: 'Settings',
      shortcuts: ['G', 'S']
    }
  },
  {
    id: 'documentation',
    label: 'Documentation',
    defaultOpen: false,
    icon: 'i-heroicons-book-open',
    children: [{
      label: 'What happened last night?',
      to: '/whathappenedlastnight',
      exact: true
    },],
    tooltip: {
      text: 'Documentation',
      shortcuts: ['G', 'D']
    }
  }
]
const workLinks = [
  {
    id: 'dashboad',
    label: 'Dashboard',
    to: '/dashboard',
    icon: 'i-heroicons-chart-pie',
    tooltip: {
      shortcuts: ['G', 'D']
    }
  },
  {
    id: 'monitor',
    label: 'Recruiting-Abstract',
    to: '/monitor',
    icon: 'i-heroicons-clipboard-document-list',
    tooltip: {
      shortcuts: ['G', 'K']
    }
  },
  {
    id: 'candidates',
    label: 'Candidates',
    to: '/candidates',
    icon: 'i-heroicons-users',
    tooltip: {
      shortcuts: ['G', 'C']
    },
    children: [
      {
        label: 'Auflistung',
        to: '/candidates',
      },
      {label: 'Talentpools', disabled: true}
    ]
  },
]
const links2 = [{
  id: 'home',
  label: 'Home',
  icon: 'i-heroicons-home',
  to: '/',
  tooltip: {
    text: 'Home',
    shortcuts: ['G', 'H']
  }
}, {
  id: 'inbox',
  label: 'Inbox',
  icon: 'i-heroicons-inbox',
  to: '/inbox',
  badge: '4',
  tooltip: {
    text: 'Inbox',
    shortcuts: ['G', 'I']
  }
}, {
  id: 'users',
  label: 'Users',
  icon: 'i-heroicons-user-group',
  to: '/users',
  tooltip: {
    text: 'Users',
    shortcuts: ['G', 'U']
  }
}, {
  id: 'settings',
  label: 'Settings',
  to: '/settings',
  icon: 'i-heroicons-cog-8-tooth',
  children: [{
    label: 'General',
    to: '/settings',
  },
    {
      label: 'Members',
      to: '/settings/members'
    }, {
      label: 'Notifications',
      to: '/settings/notifications'
    }],
  tooltip: {
    text: 'Settings',
    shortcuts: ['G', 'S']
  }
}]

const footerLinks = [{
  label: 'Invite people',
  icon: 'i-heroicons-plus',
  to: '/settings/members'
}, {
  label: 'Help & Support',
  icon: 'i-heroicons-question-mark-circle',
  to:'/whathappenedlastnight'
  //click: () => isHelpSlideoverOpen.value = true
}]

const groups = [{
  key: 'links',
  label: 'Go to',
  commands: workLinks.map(link => ({...link, shortcuts: link.tooltip?.shortcuts}))
}, {
  key: 'code',
  label: 'Code',
  commands: [{
    id: 'source',
    label: 'View page source',
    icon: 'i-simple-icons-github',
    click: () => {
      window.open(`https://github.com/nuxt-ui-pro/dashboard/blob/main/pages${route.path === '/' ? '/index' : route.path}.vue`, '_blank')
    }
  }]
}]

const selected = ref(true)

const isOpen = () => true;

</script>

<template>
  <UDashboardLayout>
    <UDashboardPanel v-model="isOpen" :width="250" :resizable="{ min: 200, max: 300 }" collapsible>
      <UDashboardNavbar class="!border-transparent" :ui="{ left: 'flex-1' }">
        <template #left>
          <!--<TeamsDropdown />-->
          <TeamsDropdown></TeamsDropdown>
        </template>
      </UDashboardNavbar>

      <UDashboardSidebar>
        <template #header>
          <UDashboardSearchButton/>
        </template>

        <UDashboardSidebarLinks :links="workLinks"/>

        <UDivider/>

        <UDashboardSidebarLinks :links="linkSettingsDocu" collapsible="false"/>

        <UDivider/>

        <div class="flex-1"/>

        <UDashboardSidebarLinks :links="footerLinks"/>

        <UDivider class="sticky bottom-0"/>

        <template #footer>
          <!-- ~/components/UserDropdown.vue
          <UserDropdown />
          -->
          <ProfileAvatar/>
        </template>
      </UDashboardSidebar>
    </UDashboardPanel>

    <slot/>

    <!-- ~/components/HelpSlideover.vue
    <HelpSlideover />-->
    <!-- ~/components/NotificationsSlideover.vue
    <NotificationsSlideover />-->

    <ClientOnly>
      <LazyUDashboardSearch :groups="groups"/>
    </ClientOnly>
  </UDashboardLayout>
</template>
